import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import moment from "moment"

import { truncateString } from "../../utils"

import styled from "styled-components"
import { categoryCovers } from "../../assets/categoryCovers/categoryCovers"
import { reduceStringByWordsSeparatedByComma } from "../../utils/reduceStringByWordsSeparatedByComma"
import CardLogo from "../CardLogo"

const WebinarItem = ({ webinar, basePath, location }) => {
  const {
    uid,
    data: {
      title,
      custom_date: customDate,
      description,
      category,
      presenter,
    },
  } = webinar
  let webinarURL = `${basePath}/${uid}`

  const url = `${webinarURL}${location?.pathname?.includes("webinar") ? `?from=${location.pathname}` : ""}`

  return (
    <BoxItem>
      <CardLogo name={category?.document?.data.name} color={category?.document?.data.color}/>
      <Box>
        <picture>
          <img src={categoryCovers[category.uid]?.background} alt=""/>
        </picture>
        <ContentWrapper color={category.document.data.color}>
          <CardTitle>{truncateString(title.text, 40)}</CardTitle>
          <Category>{category.document.data.name}</Category>
          <Date>
            {customDate ? (
              customDate
            ) : (
              <>
                {moment.utc(customDate).format("D MMM YYYY")}
                {customDate && ` - ${moment.utc(customDate).format("D MMM YYYY")}`}
              </>
            )}
          </Date>
          <Description>{truncateString(description, 120)}</Description>
          <Author>{reduceStringByWordsSeparatedByComma(presenter?.document?.data?.name)}</Author>
          <Button to={url}>Learn more</Button>
        </ContentWrapper>
      </Box>
    </BoxItem>
  )
}

WebinarItem.propTypes = {
  basePath: PropTypes.string.isRequired,
}

export default WebinarItem

const BoxItem = styled.div`
  width: 354px;
  position: relative;
  box-shadow: ${({theme}) => theme.box.boxShadow};
`
const Box = styled.div`
  height: 100%;
  text-align: center;
  min-height: 440px;
  overflow: hidden;
  opacity: ${props => (props.isPast ? "0.6" : "1.0")};
  position: relative;

  background-color: ${({ theme }) => theme.colors.secondary_color};

  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  picture {
    img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const ContentWrapper = styled.div`
  width: 70%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5rem;
  text-align: left;
  background: linear-gradient(
    180.03deg,
    ${props => props.color} 23.88%,
    rgba(68, 170, 153, 0) 121.4%
  );

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    width: 60%;
  }
`

const Date = styled.div`
  color: white;
  text-align: left;
  margin-top: 0.1rem;
  font-size: 15px;
  line-height: 1.67;
`

const Description = styled.div`
  margin-top: 0.5rem;
  font-size: 15px;
  line-height: 1.33;
  color: white;
`

const Author = styled(Description)`
  font-weight: 600;
  margin-top: 0.1rem;
`

const CardTitle = styled.div`
  font-size: 24px;
  line-height: 1.25;
  padding-left: 12px;
  font-weight: 600;
  color: white;
  position: relative;

  &::before {
    content: "";
    width: 3px;
    height: 100%;
    background: white;
    position: absolute;
    left: 0;
  }
`

const Category = styled.div`
  font-size: 24px;
  line-height: 1.25;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 21px;
  color: white;
`

const Button = styled(Link)`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.primary_color};
  color: white;
  width: 100%;
  font-size: 24px;
  line-height: 1.08;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.3s;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary_color};
  }
`
