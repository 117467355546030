import React from "react"
import PropTypes from "prop-types"

import WebinarItem from "./WebinarItem"
import styled from "styled-components"
import { MainBackground } from "../MainBackground"
import Pagination from "../Pagination"
import { Container } from "../Container"
import TabButtons from "../TabButoons/TabButtons"
import MainTitle from "../CommonComponents/MainTitle";

const WebinarIndex = ({
  webinars,
  basePath,
  pageContext,
  location,
}) => {

  return (
    <MainBackground container>
      <Container>
        <TabButtons location={location}/>
        <Box>
          <HeaderContainer variant="content">
            <MainTitle variant="h1">Retail loss webinars</MainTitle>
            <Text as="p">
              As part of the Group’s commitment to disseminating the results of
              their research as widely as possible, it regularly delivers webinars,
              which can be accessed in real time or viewed at a later date.
            </Text>
          </HeaderContainer>
          <BodyContainer>
            <Flex>
              {webinars.map((webinar, i) => {
                return  <WebinarItem key={i} webinar={webinar} basePath={basePath} location={location}/>
              })}
            </Flex>
          </BodyContainer>
        </Box>
      <Pagination data={pageContext}/>
      </Container>
    </MainBackground>
  )
}

WebinarIndex.propTypes = {
  webinars: PropTypes.array.isRequired,
  basePath: PropTypes.string.isRequired,
  isFirstPage: PropTypes.bool.isRequired,
}

export default WebinarIndex

const Box = styled.div``
const HeaderContainer = styled.div``
const BodyContainer = styled.div``

const Text = styled.p`
  ${({theme}) => theme.paragraphs.p};
`
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 38px;
`
