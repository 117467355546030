import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import styled from "styled-components"

import { MainBackground } from "../MainBackground"
import ResearchCover from "../Research/ResearchCover"
import MainTitle from "../CommonComponents/MainTitle"
import { Subtitle } from "../Event/Event"
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs"
import MyLink from "../../utils/checkExternalLink"

const Header = ({
  title,
  description,
  category,
  presenter,
  image,
  customDate,
  registerURL,
  location,
}) => {
  const fromUrl = new URLSearchParams(location?.search).get("from")

  const breadCrumbs = [
    { label: "Return to all webinars", linkUrl: fromUrl || "/webinar" },
    {
      label: `${category?.document?.data?.name}`,
      linkUrl: `/category/${category?.document.uid}`,
    },
  ]

  return (
    <MainBackground type="violet" container>
      <Box as="section">
        <Container variant="content">
          <BreadCrumbs list={breadCrumbs} />
          <MainTitle variant="h1">Webinar</MainTitle>
          <Subtitle>{title.text}</Subtitle>
          <Flex>
            <BoxLeft>
              <ResearchCover
                uid={category?.document?.uid}
                color={category?.document?.data?.color}
                name={category?.document?.data?.name}
              />
              <Presenters>
                <h4>Presenter:</h4>
                <p>{presenter?.document.data?.name}</p>
                <MyLink href={registerURL.url}>Click to view</MyLink>
              </Presenters>
            </BoxLeft>
            <BoxRight>
              <GatsbyImage image={getImage(image)} alt="thumbnail" />
              <Text>{description}</Text>
              <DateEvent>
                <p>Date and Time</p>
                <p>{customDate}</p>
              </DateEvent>
              <RegisterBtn>
                <MyLink href={registerURL.url}>Click to view</MyLink>
              </RegisterBtn>
            </BoxRight>
          </Flex>
        </Container>
      </Box>
    </MainBackground>
  )
}

Header.propTypes = {
  title: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  presenter: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
}

export default Header

const Box = styled.div`
  background-color: transparent;
  position: relative;
  padding-bottom: 40px;
`
const Container = styled.div`
  ${({ theme }) => theme.container}
`
const Flex = styled.div`
  margin: 20px 0 0;
  flex-direction: column;
  min-width: 0;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: flex-start;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    flex-direction: row;
  }
`

const BoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    width: 40%;
  }
  @media (min-width: ${({ theme }) => theme.media.desktop_min}) {
    width: 35%;
  }
`

const Presenters = styled.div`
  margin-top: 20px;

  h4,
  p {
    font-size: 16px;
    line-height: 20px;
    color: white;
    margin-bottom: 0;
  }
  h4 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  p {
    opacity: 0.7;
    margin-bottom: 5px;
  }

  a {
    font-size: 20px;
    line-height: 32px;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary_color};

    &:active,
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.primary_color};
    }
  }
`

const BoxRight = styled.div`
  background-color: ${({ theme }) => theme.colors.sixth_color};
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow: auto;
  width: 100%;
  position: relative;
  scroll-behavior: smooth;
  box-shadow: ${({ theme }) => theme.box.boxShadow};

  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    width: 60%;
    max-height: 1030px;
  }
  @media (min-width: ${({ theme }) => theme.media.desktop_min}) {
    width: 65%;
  }

  h1 {
    color: grey;
    line-height: 32px;
    margin: 0;
    font-size: 24px;
    margin-bottom: 4px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    color: white;
    margin-bottom: 10px;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 20px;
      line-height: 32px;
    }
  }
  span {
    color: #4e50f7;
  }

  .gatsby-image-wrapper {
    min-height: 360px;
    margin-bottom: 20px;
  }

  ul,
  ol {
    li {
      font-size: 16px;
      line-height: 20px;
      color: white;

      @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
`

const Text = styled.p``

const DateEvent = styled.div`
  margin-top: 30px;

  p {
    &:first-child {
      font-weight: 700;
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: 0;
      opacity: 0.5;
    }
  }
`

const RegisterBtn = styled.div`
  a {
    ${({ theme }) => theme.primaryButton};
    margin-top: 10px;
  }
`
