import React from "react"

import styled from "styled-components"
import { categoryCovers } from "../../assets/categoryCovers/categoryCovers"
import { Icon } from "../Icon"
import PropTypes from "prop-types"

const ResearchCover = ({ name, color, uid }) => {
  return (
    <CategoryImage>
      <picture>
        <img src={categoryCovers[uid]?.background} alt={name} />
      </picture>
      <CategoryImageText>
        <h3>{name}</h3>
        <Icon type="PATH" fill={color} />
      </CategoryImageText>
    </CategoryImage>
  )
}

ResearchCover.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
}

export default ResearchCover

const CategoryImage = styled.div`
  position: relative;
  box-shadow: ${({ theme }) => theme.box.boxShadow};
  max-height: 250px;
  width: 100%;
  overflow: hidden;
  
  @media (min-width: ${({ theme }) => theme.media.desktop_min}) {
    max-height: 450px;
  }

  svg {
    position: absolute;
    width: 100px;
    height: 85px;
    transform: translate(-40px, -10px);
    transition: ${({ theme }) => theme.durations.transition};
    margin-left: -40px;
    margin-top: 10px;

    @media (min-width: calc(${({ theme }) => theme.media.mobile_max} + 1px)) {
      width: 128px;
      height: 116px;
      margin-left: -20px;
    }

    path {
      fill: ${props => props.color};
    }
  }

  picture {
    display: flex;
    aspect-ratio: 5/6;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`

const CategoryImageText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: grid;
  place-items: center;

  h3 {
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 20px;
    max-width: 150px;
    text-transform: uppercase;
    @media (min-width: calc(${({ theme }) => theme.media.mobile_max} + 1px)) {
      font-size: 23px;
      max-width: 215px;
      padding-left: 40px;
    }
  }
`
